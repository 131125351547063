/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@700&display=swap');

/* Body Styling */
body {
  margin: 0;
  padding: 0;
  font-family: 'Unbounded', cursive;
  color: #f4f4f4;
}

/* Schedule Button */
.sc-p {
  padding: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: #e1c072;
  font-size: 1.2rem;
  background: none;
  border: none;
}

/* Container */
.container-h {
  padding: 0;
  width: 100%;
  margin: auto;
}

.container-h.scrolled .navbar {
  background-color: #2c4464;
}

.container-h.scrolled .logo-h {
  width: 86px; /* Adjust the size as needed */
}

/* Title */
.box-title {
  text-align: center;
  font-size: 35px;
}

.logo-h {
  width: 100px;
  height: auto;
  filter: drop-shadow(0px 0px 6px #e29d11);
  padding: 1px;
  margin-left: 85px;
  transition: width 0.5s ease; /* Add transition for smooth size change */
}

.navbar {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: start;
  padding: 10px 0;
  transition: background-color 0.5s ease;
  gap: 10rem;
}

/* Navbar List */
.navbar-list {
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: center;
}

.navbar-list.mobile-open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #2c4464;
  width: 100%;
  z-index: 1000;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  align-items: center;
}

/* Navbar Button */
.nav-button {
  background: #2c4464;
  color: #f4f4f4;
  padding: 18px 10px;
  border-radius: 60px;
  font-size: 15px;
  margin-left: 4px;
  border: none;
}

/* Navbar Items */
.navbar-list .navbar-items {
  list-style-type: none;
  padding: 17px 30px;
}

.navbar-list .navbar-items:hover {
  background-color: #2c4464;
  border-radius: 30px;
}

.navbar-list .navbar-items a {
  text-decoration: none;
  color: #e1c072;
  font-size: 1.2rem;
}

.navbar-list .navbar-items a:hover {
  background-color: #2c4464;
  color: #e1c072;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: #f4f4f4;
}

/* Mobile Menu Styles */
@media (max-width: 768px) {
  .navbar-list {
    display: none;
  }

  .navbar-list.mobile-open {
    display: flex;
  }

  .hamburger {
    display: block;
  }

  .nav-button {
    display: none;
  }

  .navbar-items {
    padding: 15px;
    text-align: center;
  }

  .navbar-items a {
    font-size: 1.5rem;
    color: #e1c072;
  }

  .logo-h {
    margin-left: 50px;
  }

  .ham {
    color: rgb(232, 11, 11);
  }

  .ham2 {
    color: rgb(12, 14, 44);
    filter: drop-shadow(0px 0px 6px #e29d11);
    width: 40px;
    height: auto;
}
.navbar-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.navbar-list.mobile-open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 116px;
  left: 0;
  background-color: #2c4464;
  width: 100%;
  z-index: 1000;
}

  .navbar {
    gap: 34%;
  }
}

/* Tablet Menu Styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .navbar {
    gap: 60%;
  }

  .ham {
    color: rgb(232, 11, 11);
  }

  .ham2 {
    color: rgb(12, 14, 44);
    filter: drop-shadow(0px 0px 6px #e29d11);
    width: 40px;
    height: auto;
}

  .navbar-list.mobile-open {
    top: 100px;
  }
}

/* Dummy Text */
.dummy-text {
  font-family: 'Roboto', sans-serif;
  padding: 10px 70px;
  line-height: 1.6;
  margin: auto;
}

/* Demo Image */
.demo-img {
  float: right;
  padding: 0 0 10px 30px;
}
