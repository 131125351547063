.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    /*background-image: url('https://cdn.dribbble.com/users/2441534/screenshots/5695972/media/c5ec90bd1287a162248f8e427c348c4f.gif'); */
    /*background-size: fill;  Ensure the image covers the entire container */
    background-position:center; /* Center the image within the container */
    background-repeat: no-repeat; /* Prevent image from repeating */
    
  }
  
  .spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    
    animation: spin 3s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  @media only screen and (max-width: 767px){
    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
     
     /* background-image: url('https://cdn.dribbble.com/users/2441534/screenshots/5695972/media/c5ec90bd1287a162248f8e427c348c4f.gif');Replace with your image path */
      background-size: fill; /* Ensure the image covers the entire container */
      background-position:center; /* Center the image within the container */
      background-repeat: no-repeat; /* Prevent image from repeating */
    }
  }


  